<template>
    <div class="StoreMarketingThree">
        <div class="MarketingThreeHeader">
            <el-button class="deleBtn" @click="deleSelect">删除</el-button>
            <div class="headerRight">
                <span>使用官方数据</span>
                <el-select v-model="promotionClassify" placeholder="请选择" style="width: 150px;margin-left: 10px" @change="selectOfficialData">
                    <el-option
                            v-for="item in classifyOptions"
                            :key="item.id"
                            :label="item.marketing_name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="MarketingThreeTable">
            <div class="table">
                <div class="tableHeader">
                    <div class="headerItem" style="padding-left: 30px;position: relative">活动类型<input v-if="tableData.length" type='checkbox' class='input-checkbox' :checked="fruitIds.length === tableData.length" @click='checkedAll()'></div>
                    <div class="headerItem" style="width: 30%;">促销内容</div>
                    <div class="headerItem">新增内容</div>
                    <div class="headerItem">分值({{scoreTotal}})</div>
                    <div class="headerItem">操作</div>
                </div>
                <div class="NoInformation" v-if="!tableData.length">
                    <img src="./../../../assets/images/NoInformation.png" alt="">
                    <span>暂无符合条件的数据记录</span>
                </div>
                <div class="tableBody" v-for="(item,index) in tableData" :key="index">
                    <input type="checkbox" :checked="fruitIds.indexOf(item)>=0" name="checkboxinput" class="input-checkOne" @click='checkedOne(item)'>
                    <div class="itemBox">
                        <div class="bodyItem" style="padding-left: 30px">
                            <el-select v-model="item.type" placeholder="请选择" style="width: 150px;" @change="selectActivityType" >
                                <el-option
                                        v-for="act in activityList"
                                        :key="act.type"
                                        :label="act.name"
                                        @click.native="clickActivityType(index,act.type)"
                                        :value="act.type">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="bodyItem"></div>
                        <div class="bodyItem" style="width: 19%;"><span style="cursor: pointer;color: #1122D8" @click="addShop(item,index)">新增商品</span> <span v-if="item.type!=3" @click="addDiscounts(index)" style="cursor: pointer;color: #1122D8">新增优惠</span></div>
                        <div class="bodyItem"><el-input disabled oninput="value=value.replace(/[^\d]/g,'')" v-model="item.total_score" style="width: 100px;margin-right: 6px"></el-input>分</div>
                        <div class="bodyItem">
                          <span style="color: #EB6547;cursor: pointer" @click="deleActivity(index)">删除</span>
                        </div>
                    </div>
                    <div class="itemBoxBj" v-for="(itemData,indexData) in item.data" :key="itemData.id">
                        <div class="itemBox" v-if="itemData.goods_data">
                            <div class="bodyItem">
                            </div>
                            <div class="bodyItem">
                                <el-select v-model="itemData.goods_data.id" placeholder="请选择" style="width: 150px;" @change="selectShopData">
<!--                                    <el-option key="0" @click.native="clickSlectAllData(item,index,indexData)" label="全部" value="0"></el-option>-->
                                    <el-option
                                            @click.native="clickSlectData(item,index,indexData,goodsData.id)"
                                            v-for="goodsData in goods_data"
                                            :key="goodsData.id"
                                            :label="goodsData.name"
                                            :value="goodsData.id">
                                    </el-option>
                                </el-select>
                                <span v-if="item.type == 3" style="margin-left: 10px">优惠金额<el-input oninput="value=value.replace(/[^\d]/g,'')" style="width: 60px;margin-left: 2px" v-model="itemData.yh_sum"></el-input></span>
                            </div>
                            <div class="bodyItem"></div>
                            <div class="bodyItem"><el-input oninput="value=value.replace(/[^\d]/g,'')" @input="getTotal(item, index)" v-model="itemData.score" style="width: 100px;margin-right: 2px"></el-input>分</div>
                            <div class="bodyItem">
                              <span style="color: #EB6547;cursor: pointer" @click="deltSecond(index,indexData, item)">删除</span>
                            </div>
                        </div>
                    </div>
                    <div class="itemBox" v-for="(items,itemIndex) in item.data" :key="items.id" v-if="!items.goods_data && item.type == 1">
                        <div class="bodyItem">
                        </div>
                        <div class="bodyItem">
                            满<el-input oninput="value=value.replace(/[^\d]/g,'')" v-model="items.man" @input="watchMoney(index,itemIndex)" style="width: 60px;margin: 0 2px"></el-input>元 ~ 减<el-input oninput="value=value.replace(/[^\d]/g,'')" @input="watchMoney(index,itemIndex)" v-model="items.jian" style="width: 60px;margin: 0 2px"></el-input>元
                        </div>
                        <div class="bodyItem"></div>
                        <div class="bodyItem"><el-input oninput="value=value.replace(/[^\d]/g,'')" @input="getTotal(item, index)" v-model="items.score" style="width: 100px;margin-right: 6px"></el-input>分</div>
                        <div class="bodyItem">
                          <span style="color: #EB6547;cursor: pointer" @click="deleFullEeduction(index,itemIndex, item)">删除</span>
                        </div>
                    </div>

                    <div class="itemBox" v-for="(items2,items2Index) in item.data" :key="items2.id" v-if="!items2.goods_data && item.type == 2">
                        <div class="bodyItem">
                        </div>
                        <div class="bodyItem">
                            满<el-input oninput="value=value.replace(/[^\d]/g,'')" v-model="items2.rule.sum" style="width: 60px;margin: 0 2px"></el-input>元 ~ 送
                            <el-select v-model="items2.rule.shopclass" placeholder="请选择" style="width: 150px;" @change="selectShopData">
                                <el-option
                                        @click.native="selectGive(index,items2Index,goodsData.id)"
                                        v-for="goodsData in goods_data"
                                        :label="goodsData.name"
                                        :value="goodsData.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="bodyItem"></div>
                        <div class="bodyItem"><el-input oninput="value=value.replace(/[^\d]/g,'')" @input="getTotal(item, index)" v-model="items2.score" style="width: 100px;margin-right: 6px"></el-input>分</div>
                        <div class="bodyItem">
                          <span style="color: #EB6547;cursor: pointer" @click="deleTypeOne(index,items2Index, item)">删除</span>
                        </div>
                    </div>
                    <div class="itemBox" v-for="(items,itemIndex) in item.data" :key="items.id" v-if="!items.goods_data && item.type == 4">
                        <div class="bodyItem">
                        </div>
                        <div class="bodyItem">
                            满<el-input oninput="value=value.replace(/[^\d]/g,'')" @input="watchMoney(index,itemIndex)" v-model="items.man" style="width: 60px;margin: 0 2px"></el-input>元 ~ 减<el-input oninput="value=value.replace(/[^\d]/g,'')" @input="watchMoney(index,itemIndex)" v-model="items.jian" style="width: 60px;margin: 0 2px"></el-input>元
                        </div>
                        <div class="bodyItem"></div>
                        <div class="bodyItem"><el-input oninput="value=value.replace(/[^\d]/g,'')" @input="getTotal(item, index)" v-model="items.score" style="width: 100px;margin-right: 6px"></el-input>分</div>
                        <div class="bodyItem">
                          <span style="color: #EB6547;cursor: pointer" @click="deleTypeFour(index,itemIndex, item)">删除</span>
                        </div>
                    </div>
<!--                    <div class="itemBox" v-for="items2 in item.data" :key="items2.id" v-if="item.type == 3 && item.yh_sum">-->
<!--                        <div class="bodyItem">-->
<!--                        </div>-->
<!--                        <div class="bodyItem">-->
<!--                           优惠金额 <el-input v-model="items2.yh_sum" style="width: 60px;"></el-input>-->
<!--                        </div>-->
<!--                        <div class="bodyItem"></div>-->
<!--                        <div class="bodyItem"><el-input v-model="items2.score" style="width: 100px;margin-right: 6px"></el-input>分</div>-->
<!--                        <div class="bodyItem" style="color: #EB6547">删除</div>-->
<!--                    </div>-->

                </div>
            </div>
            <el-button style="background: #ffffff;border-color: #1122D8;color: #1122D8;margin-top: 10px" @click="addActivity">添加活动</el-button>
        </div>
        <div class="toBtn">
            <el-button class="btn1" @click="toBack">上一步</el-button>
            <el-button class="btn1" @click="toNext">下一步</el-button>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import _ from "underscore";
    export default {
        name: "StoreMarketingThree",
        data(){
            return {
                fruitIds:[],
                isCheckedAll: false,
                promotionClassify:'',
                activityClassify:'',
                typeVale:'',
                activityList:[
                    {
                        type:'1',
                        name:'满减促销'
                    },
                    {
                        type:'2',
                        name:'满元赠促销'
                    },
                    {
                        type:'3',
                        name:'套装促销'
                    },
                    {
                        type:'4',
                        name:'优惠券'
                    },
                ],
                category_id:'',
                classifyOptions:[],
                tableData:[],
                tableData2:[],
                goods_data:[],
                classNum:['0'],
                // scoreTotal:null,
                repetitionId1:null,
                repetitionId2:null,
                repetitionId3:null,
                repetitionId4:null,
            }
        },
        computed: {
            ...mapGetters([
                'goodsList',
            ]),
          scoreTotal() {
            let total = 0
            if (Array.isArray(this.tableData)) {
              this.tableData.forEach(item => {
                if (isNaN(Number(item.total_score))) {
                  total += 0
                } else {
                  total += Number(item.total_score)
                }
              })
            }
            return total
          }
        },
        created() {
            this.getShowStoreMarket();
            // this.goods_data = JSON.parse(this.$route.query.goods_data);
            this.goods_data = _.map(this.goodsList, (item) => {
                let tmp = {
                    id: item.id,
                    name: item.name,
                    image: item.image,
                    inventory: item.inventory,
                    price: item.price
                }
                return tmp;
            });
            this.getEditData();
        },
        /*activated(){
            this.goods_data = JSON.parse(this.$route.query.goods_data);
        },*/
        methods: {
            checkedOne(fruitId){
                let idIndex = this.fruitIds.indexOf(fruitId);
                if(idIndex >= 0){
                    this.fruitIds.splice(idIndex, 1)
                } else {
                    {
                        // 选中该checkbox
                        this.fruitIds.push(fruitId)
                    }
                }
            },
            checkedAll(){
                this.isCheckedAll = !this.isCheckedAll;
                if (this.isCheckedAll){
                    this.fruitIds = [];
                    this.tableData.forEach(function (fruit) {
                        this.fruitIds.push(fruit)
                    },this)
                } else {
                    this.fruitIds = []
                }
            },
            deleSelect(){
              for(let i = 0; i < this.tableData.length; i++){
                  for(let j = 0; j < this.fruitIds.length; j++){
                      if(this.tableData[i] === this.fruitIds[j]){
                          this.tableData.splice(i,1)
                      }
                  }
              }
            },
            getEditData(){
                if(this.$route.query.resData){
                    let resData = JSON.parse(this.$route.query.resData);
                    this.tableData = JSON.parse(resData.html_data);
                    // this.tableData.forEach((item)=>{
                    //     this.scoreTotal+=item.total_score
                    // })
                    // console.log(this.tableData)
                    this.tableData.map((item) => {
                        if (item.data.length > 0) {
                            item.data.map((val) => {
                                if (val.rule && /#/.test(val.rule)) {
                                    let [man, jian] = val.rule.split('#');
                                    val.man = man;
                                    val.jian = jian;
                                }
                                if (val.rule && val.rule.goods_data) {
                                    val.rule.shopclass = Number(val.rule.goods_data.id);
                                }
                            })
                        }
                    });
                    // console.log('table', this.tableData);
                    for(let i = 0; i < this.tableData.length; i++) {
                        for (let j = 0; j < this.tableData[i].data.length; j++) {
                            if(this.tableData[i].data[j].shopClassify){
                                this.tableData[i].data[j].shopClassify = ''
                            }
                            if (this.tableData[i].data[j].goods_data) {
                                this.tableData[i].data[j].goods_data.id = Number(this.tableData[i].data[j].goods_data.id);
                            }
                        }
                    }
                }
            },
            getShowStoreMarket(){
                let param = {
                    type:'all'
                };
                this.$http.axiosGetBy(this.$api.showStoreMarket, param, res=>{
                    if(res.code === 200){
                        this.classifyOptions = res.data
                    } else {
                        this.$message.warning(res.mag)
                    }
                })
            },
            selectOfficialData(val){
                this.$http.axiosGetBy(this.$api.showStoreMarket, {id:val}, res=>{
                    if(res.code === 200){
                        this.tableData = JSON.parse(res.data.data[0].html_data)
                        for(let i = 0; i < this.tableData.length; i++) {
                            for (let j = 0; j < this.tableData[i].data.length; j++) {
                                if(this.tableData[i].data[j].shopClassify){
                                    this.tableData[i].data[j].shopClassify = ''
                                }
                            }
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            handleSelectionChange(val){
                // console.log(96,val)
            },
            toBack(){
                this.$router.go(-1)
            },
            addActivity(){
                if(this.tableData.length === 4){
                    this.$message.warning('最多创建四个活动！');
                    return
                }
                let obj = {
                    'type':'请选择',
                    'data':[],
                    'total_score':''
                };
                this.tableData.push(obj);
            },
            addShop(item,index){
                if(item.type == '请选择'){
                    this.$message.warning('请先选择活动类型！');
                    return;
                }
                let list = [];
                this.tableData[index].data.map(item=>{
                    if(item.goods_data ){
                        list.push(item);
                    }
                })
                if(list.length == this.goods_data.length){
                    this.$message.warning('最多增加'+this.goods_data.length+'个商品！');
                    return
                }
                // if(this.tableData[index].data.length === this.goods_data.length){
                //     this.$message.warning('已经是全部商品！');
                //     return
                // }
                if(item.type == 3){
                    let obj = {
                        goods_data: {},
                        yh_sum:'',
                        score: '',
                        shopClassify: '',
                    }
                    this.tableData[index].data.unshift(obj)
                } else {
                    let obj = {
                        goods_data: {},
                        score: '',
                        shopClassify: '',
                    }
                    this.tableData[index].data.unshift(obj)
                }

            },
            addDiscounts(index){
                if(this.tableData[index].type == 1){
                    let list = [];
                    this.tableData[index].data.map(item=>{
                        if(item.rule == '' ){
                            list.push(item);
                        }
                    })
                    if(list.length === 3){
                        this.$message.warning('最多添加3个满减优惠！');
                        return
                    }
                    let obj = {
                        rule:'',
                        score:'',
                        man:'',
                        jian:''
                    };
                    this.tableData[index].data.push(obj)
                } else if (this.tableData[index].type == 2){
                    let obj2 = {
                        rule:{
                            sum:'',
                            shopclass:'',
                            goods_data:{}
                        },
                        score:''
                    }
                    this.tableData[index].data.push(obj2)
                } else if (this.tableData[index].type == 4){
                    let list = [];
                    this.tableData[index].data.map(item=>{
                        if(item.rule == '' ){
                            list.push(item);
                        }
                    })
                    if(list.length === 1){
                        this.$message.warning('最多添加1个优惠券！');
                        return
                    }
                    let obj = {
                        rule:'',
                        score:'',
                        man:'',
                        jian:''
                    };
                    this.tableData[index].data.push(obj)
                } else {
                    this.$message.warning('请先选择活动类型！')
                }

            },
            clickSlectData(item,index,indexData,id){
                _.each(this.goods_data, (item) => {
                    if (Number(item.id) === Number(id)) {
                        this.tableData[index].data[indexData].goods_data = Object.assign({}, item);
                    }
                });
                    /*this.goods_data.map(item2=>{
                        if(Number(item2.id) === Number(id)){
                            this.tableData[index].data[indexData].goods_data = item2;
                        }
                    })*/
              // this.tableData[index].data[indexData].goods_data;
            },
            // clickSlectAllData(item,index,indexData){
            //     console.log(358,this.tableData[index].data)
            //     this.goods_data.map(item2=>{
            //         let obj = {
            //             goods_data:{
            //                 id:item2.id,
            //                 image:item2.image,
            //                 inventory:item2.inventory,
            //                 name:item2.name,
            //                 price:item2.price,
            //             },
            //             score:'',
            //             shopClassify:''
            //         }
            //         this.tableData[index].data.push(obj)
            //     })
            //     console.log(359,this.tableData[index].data)
            // },
            deleActivity(index){
              this.tableData.splice(index,1)
            },
            deleTableData(row){
                for(let i =0; i<this.tableData.length; i++){
                    if(this.tableData[i].id == row.id){
                        this.tableData.splice(i,1)
                    }
                }
            },
            deltSecond(index,indexData, list){
              this.tableData[index].data.splice(indexData,1)
              this.getTotal(list, index)
            },
            deleFullEeduction(index,itemIndex, list){
                this.tableData[index].data.splice(itemIndex,1)
                this.getTotal(list, index)
            },
            deleTypeOne(index,items2Index, list){
                this.tableData[index].data.splice(items2Index,1)
                this.getTotal(list, index)
            },
            deleTypeFour(index,itemIndex, list){
                this.tableData[index].data.splice(itemIndex,1)
                this.getTotal(list, index)
            },
            //选择促销商品
            selectShopData(val){
                // console.log(370,val)
            },
            selectActivityType(val){

            },
            // watchScore(index){
            //   console.log('input', index)
            //   let num = 0;
            //   let data = this.tableData[index].data;
            //     data.map(item=>{
            //       if(item.score){
            //           num += JSON.parse(item.score)
            //       }
            //   })
            //     this.tableData[index].total_score = num;
            //     let num2 = 0;
            //     this.tableData.map(item=>{
            //         num2 += item.total_score
            //     })
            //     this.scoreTotal = num2
            // },
            getTotal(list, index) {
              let total = 0
              if (Array.isArray(list.data)) {
                list.data.forEach(item => {
                  if (isNaN(Number(item.score))) {
                    total += 0
                  } else {
                    total += Number(item.score)
                  }
                })
              }
              this.tableData[index].total_score = total
              return total
            },
            watchMoney(index,itemIndex){
                this.tableData[index].data[itemIndex].rule =  this.tableData[index].data[itemIndex].man + '#' + this.tableData[index].data[itemIndex].jian;
            },
            selectGive(index,items2Index,id){
                this.goods_data.map(item=>{
                    if(item.id === id){
                        this.tableData[index].data[items2Index].rule.goods_data = item
                    }
                })
            },
            clickActivityType(index, type){
                let list = [];
                this.tableData.map(item=>{
                    if(item.type === type){
                        list.push(item)
                    }
                })
                if(list.length >= 2){
                    this.$message.warning('每种活动类型只能选择一次！');
                    this.tableData[index].type = '';
                    this.tableData[index].data =[];
                    return
                }
                this.tableData[index].type = type;
                this.tableData[index].data =[];
            },
            toNext(){
                // return;
                this.tableData2 = JSON.stringify(this.tableData);
                if(this.tableData.length < 1){
                    this.$message.warning('请添加活动类型！');
                    return;
                }
                for(let i = 0; i < this.tableData.length; i++){
                    if(this.tableData[i].data.length){
                        for(let j = 0; j < this.tableData[i].data.length; j++){
                            if(this.tableData[i].data[j].score == ''
                                || this.tableData[i].data[j].goods_data == {}
                                    || this.tableData[i].data[j].yh_sum == ''
                                        || this.tableData[i].data[j].man == ''
                                            || this.tableData[i].data[j].jian == ''
                                                || (JSON.stringify(this.tableData[i].data[j].goods_data) === '{}' && this.tableData[i].data[j].shopClassify === ''))
                            {
                                this.$message.warning('每项为必填！');
                                return;;
                            } else if(this.tableData[i].data[j].rule){
                                if(this.tableData[i].data[j].rule.goods_data == {} || this.tableData[i].data[j].rule.sum == ''){
                                    this.$message.warning('每项为必填！');
                                    return;;
                                }
                            }
                        }
                    } else {
                        this.$message.warning('请添加商品数据！');
                        return;
                    }
                }
                let table = this.tableData;
                // for(let i = 0; i < table.length; i++){
                //     for(let j = 0; j < table[i].data.length; j++){
                //         if(table[i].data[j].goods_data){
                //             table[i].data.unshift(table[i].data[j].goods_data);
                //             table[i].data.splice(j+1,1)
                //         }
                //     }
                // }
                // console.log(441,table)
                for(let i = 0; i < table.length; i++){
                    for(let j = 0; j < table[i].data.length; j++){
                        if(table[i].data[j].hasOwnProperty('shopClassify')){
                            delete table[i].data[j].shopClassify
                        }
                        if(table[i].data[j].jian){
                            delete table[i].data[j].jian
                        }
                        if(table[i].data[j].man){
                            delete table[i].data[j].man
                        }
                        if(table[i].data[j].rule){
                            if(table[i].data[j].rule.shopclass){
                                delete table[i].data[j].rule.shopclass
                            }
                        }
                    }
                }
                let oneForm = JSON.parse(this.$route.query.oneForm);
                // let goodsData = JSON.parse(this.$route.query.goods_data);
                let goodsData = _.map(this.goodsList, (goods) => {
                    let tmp = {
                        id: goods.id,
                        image: goods.image,
                        inventory: goods.inventory,
                        name: goods.name,
                        price: goods.price
                    };
                    return tmp;
                });
                let activity_score = JSON.stringify(table);
                let storeFile = this.$route.query.storeFile;
                // let suitFile = JSON.stringify(this.$route.query.suitFile);
                // let discountsFile = JSON.stringify(this.$route.query.discountsFile);
                let formData = new FormData();
                if(this.$route.query.resData){
                    formData.append('id',JSON.parse(this.$route.query.resData).id)
                }
                formData.append('marketing_name',oneForm.marketing_name);
                formData.append('category_id',oneForm.category_id);
                formData.append('remark',oneForm.remark);
                formData.append('store_file',storeFile);
                // formData.append('suit_file',suitFile);
                // formData.append('discount_file',discountsFile);
                formData.append('goods_data',JSON.stringify(goodsData));
                formData.append('activity_score',activity_score);
                formData.append('category_name',oneForm.category_name);
                formData.append('html_data',this.tableData2)
                this.$http.axiosPost(this.$api.saveStoreMarket, formData, res=>{
                    if(res.code === 200){
                        this.$router.push({
                            path: '/admin/operatedata/storemarketingsucceed'
                        })
                    } else {
                      console.log(res)
                        this.$message.warning(res.msg)
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .NoInformation{
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        /*justify-content: center;*/
        align-items: center;
        img{
            width: 254px;
            height: 180px;
        }
        span{
            margin-top: 50px;
            color: #878994;
            font-size: 14px;
        }
    }
    table tr td{
        border-bottom: 1px solid #EEEEEE;
    }
    .StoreMarketingThree{
        height: 100%;
        .MarketingThreeHeader{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .deleBtn{
                width: 90px;
                height: 30px;
                line-height: 0;
                color: #EB6547;
                background: #ffffff;
                border-color: #EB6547;
            }
        }
        .toBtn{
            display: flex;
            justify-content: center;
            margin-top: 80px;
            .btn1{
                width: 100px;
                height: 30px;
                line-height: 0;
            }
            .btn1:nth-child(1){
                background: #ffffff;
                border-color: #1122D8;
                color: #1122D8;
            }
            .btn1:nth-child(2){
                background: #1122D8;
                border-color: #1122D8;
                color: #ffffff;
            }
        }
        .MarketingThreeTable{
            .table{
                margin-top: 10px;
                display: flex;
                flex-direction: column;
                border: 1px solid #EEEEEE;
                .tableHeader{
                    display: flex;
                    text-align: center;
                    .input-checkbox{
                        position: absolute;
                        left: 6px;
                        top:20px;
                        width: 12px;
                        height: 12px;
                        /*border-color: red!important;*/
                    }
                    .headerItem{
                        width: 20%;
                        background: #F2F3FA;
                        height: 60px;
                        line-height: 60px;
                        padding-left: 10px;
                    }
                }
                .tableBody{
                    position: relative;
                    .input-checkOne{
                        position: absolute;
                        left: 6px;
                        top: 20px;
                        width: 12px;
                        height: 12px;
                        border: 1px solid red;
                    }
                    .itemBox{
                        display: flex;
                        .bodyItem:nth-child(2){
                            width: 30%;
                        }
                        .bodyItem{
                            text-align: center;
                            width: 20%;
                            height: 60px;
                            line-height: 60px;
                            padding-left: 10px;
                            border-bottom: 1px solid #EEEEEE;
                        }
                    }

                }
            }
            .tableBox{
                margin-top: 10px;
                border-spacing:0;
                width: 100%;
                border: 1px solid #EEEEEE;

                tr{
                    height: 60px;
                    th{
                        font-size: 14px;
                    }
                }
                .newContent{
                    span{
                        color: #343441;
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
            .noData{
                color: #878994;
                font-size: 16px;
                position: absolute;
                left: 48%;
                bottom: 10px;
            }
        }
    }
</style>